import * as React from "react";
import { BadgeElement, TextElement, TextNodeElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkBadgeStandard } from "@egds/react-core/badge";
import { mjRenderChildren } from "../MJRenderChildren";

export const MJBadge: React.FC<{
  element: BadgeElement;
}> = ({ element }) => {
  const textChildren = element.children.find(({ name }) => name === "TextElement");
  const textNodeChildren = element.children.find(({ name }) => name === "TextNodeElement");

  var textColor: string | undefined;
  var text: string | undefined;

  if (textChildren) {
    textColor = (textChildren as TextElement).color;
    text = ((textChildren as TextElement).children.find(({ name }) => name === "TextNodeElement") as any)?.text;
  } else if (textNodeChildren) {
    text = (textNodeChildren as TextNodeElement).text;
  }

  return (
    <span
      style={
        { "--egds-surface-container": element.backgroundColor, "--egds-on-surface": textColor } as React.CSSProperties
      }
    >
      <UitkBadgeStandard size={(element.size ?? "large") as any} className="uitk-badge-base-has-text" text={text}>
        {mjRenderChildren(
          element.children.filter(({ name }) => !(name === "TextNodeElement" || name === "TextElement"))
        )}
      </UitkBadgeStandard>
    </span>
  );
};

export default MJBadge;

import * as React from "react";
import { CardContentSectionElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkCardContentSection, UitkCardContentSectionBorder } from "@egds/react-core/cards";
import { mjRenderChildren } from "../MJRenderChildren";

export const MJCardContentSection: React.FC<{
  element: CardContentSectionElement;
}> = ({ element }) => {
  const props = {
    border: element.border as UitkCardContentSectionBorder,
  };

  return (
    <UitkCardContentSection {...props} padded={false}>
      {mjRenderChildren(element.children)}
    </UitkCardContentSection>
  );
};

export default MJCardContentSection;

import * as React from "react";
import { ImageElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkFigure, UitkImage, UitkFigureAspectRatioType, UitkImageFit } from "@egds/react-core/images";

const ratioMap: any = {
  ratio1x1: UitkFigureAspectRatioType.R1_1,
  ratio3x2: UitkFigureAspectRatioType.R3_2,
  ratio3x4: UitkFigureAspectRatioType.R3_4,
  ratio4x1: UitkFigureAspectRatioType.R4_1,
  ratio4x3: UitkFigureAspectRatioType.R4_3,
  ratio16x9: UitkFigureAspectRatioType.R16_9,
  ratio21x9: UitkFigureAspectRatioType.R21_9,
};

const roundCornerMap: any = {
  topLeft: "startstart",
  topRight: "startend",
  bottomLeft: "endstart",
  bottomRight: "endend",
};

type LazyLoadingType = "lazy" | "eager" | "off";

export const MJImage: React.FC<{
  element: ImageElement;
}> = ({ element }) => {
  const ratio = element.circular ? UitkFigureAspectRatioType.R1_1 : ratioMap[element.aspectRatio as string];
  const roundcorner = element.circular ? ["max"] : element.roundCorners?.map((c) => roundCornerMap[c]);

  const figureProps = {
    ratio,
    imageFit: element.fit as UitkImageFit,
    roundcorner,
  };

  const imageProps = {
    alt: element.accessibilityLabel,
    src: element.src,
    blankDefault: element.blankDefault,
    placeholderImage: element.placeholderImage,
    lazyLoading: element.lazyLoading as LazyLoadingType,
  };

  if (!ratio) {
    // eslint is not smart enough to figure out we already have `alt` in the props object
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...imageProps} style={{ maxWidth: "100%", display: "block" }} />;
  }

  return (
    <UitkFigure {...figureProps}>
      <UitkImage {...imageProps} />
    </UitkFigure>
  );
};

export default MJImage;

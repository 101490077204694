export function getPixelNumber(pixelString?: string): number | null {
  if (pixelString) {
    const px = pixelString.slice(-2);
    if (px.match("px")) {
      const result = parseInt(pixelString.slice(0, -2));
      return isNaN(result) ? null : result;
    }
  }
  return null;
}

import * as React from "react";
import { ButtonElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkPrimaryButton, UitkSecondaryButton, UitkTertiaryButton, UitkOverlayButton } from "@egds/react-core/button";
import { mjRenderChildren } from "../MJRenderChildren";
import { useMojoAction } from "../actions/execute";

interface SizeMap {
  small: string;
  medium: string;
  large: string;
}

const buttonTypeMap: any = {
  primary: UitkPrimaryButton,
  secondary: UitkSecondaryButton,
  tertiary: UitkTertiaryButton,
  overlay: UitkOverlayButton,
};

const sizeMap: SizeMap = {
  small: "small",
  medium: "medium",
  large: "large",
};

export const MJButton: React.FC<{ element: ButtonElement }> = ({ element }) => {
  const { execute, link } = useMojoAction(element.action);

  const linkProps = link ? { tag: "a", href: link.href } : {};
  const B = buttonTypeMap[element.type ?? ""] ?? UitkPrimaryButton;

  return (
    <B
      {...linkProps}
      disabled={element.disabled}
      size={sizeMap[(element.size as keyof SizeMap) ?? ""] ?? "medium"}
      className="uitk-button-has-text"
      onClick={execute}
    >
      {mjRenderChildren(element.children)}
    </B>
  );
};

export default MJButton;

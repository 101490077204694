import * as React from "react";
import { IconElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";

interface SizeMap {
  "16px": string;
  "18px": string;
  "24px": string;
  "32px": string;
  "48px": string;
}

const sizeMap: SizeMap = {
  "16px": "xsmall",
  "18px": "small",
  "24px": "medium",
  "32px": "large",
  "48px": "xlarge",
};

export const MJIcon: React.FC<{
  element: IconElement;
  className?: string;
}> = ({ element, className }) => {
  const props = {
    name: element.icon as string,
    title: element.title,
    description: element.description,
    size: (sizeMap[element.size as keyof SizeMap] ?? undefined) as UitkIconSize,
    spotlight: element.spotlightColor ? "override" : (undefined as any),
  };

  return (
    <>
      <span
        style={
          {
            display: "flex",
            alignItems: "center",
            "--int__egds-icons__theme": element.color,
            "--int__egds-icons__theme__background": element.spotlightColor,
          } as React.CSSProperties
        }
      >
        <UitkIcon {...props} className={className} data-testid="mojo-icon" />
      </span>
    </>
  );
};

// Required for UitkLink to properly size the embeded icons.
MJIcon.displayName = "UitkIcon";

export default MJIcon;
